<template>
<div>
  <datatable-heading :title="$t('menu.reports')" :changePageSize="changePageSize" :searchChange="_.debounce(searchChange, 500)" :from="from" :to="to" :total="total" :perPage="perPage" :hasSearch="false">
    <template v-slot:button>
      <download-excel type="csv">
        <b-button v-b-modal.modalright variant="primary" size="lg">
          Export
        </b-button>
      </download-excel>
    </template>
  </datatable-heading>
  <b-row>
    <b-col md="2" sm="12">
      <b-form-group label="Report">
        <multiselect placeholder="Select Report" :multiple="false" :options="[]" />
      </b-form-group>
    </b-col>
    <b-col md="2" sm="12">
      <b-form-group label="Start Date">
        <b-form-input type="date" />
      </b-form-group>
    </b-col>
    <b-col md="2" sm="12">
      <b-form-group label="End Date">
        <b-form-input type="date" />
      </b-form-group>
    </b-col>
    <b-col md="2" sm="12">
      <b-form-group label="Customer Care Specialist">
        <multiselect placeholder="Select specialist" :value="customerCare" :multiple="true" :options="customerCareOptions" track-by="employeeID" label="displayName" :close-on-select="false" @select="e => handleOptionAdd(e, customerCareSpecialist, 'customerCare')" @remove="e =>handleOptionRemove(e, customerCareSpecialist, 'customerCare')">
          <template slot="selection" slot-scope="{values, remove}"> <span v-for="option in values" :key="option.programID">
              <span v-if="option.displayName !== 'Select All'" class="multiselect__tag">
                {{ option.displayName }}<i class="multiselect__tag-icon" @click="remove(option)"></i></span>
            </span></template></multiselect>
      </b-form-group>
    </b-col>
    <b-col md="2" sm="12">
      <b-form-group label="Program">
        <multiselect placeholder="Select Program" :value="program" :multiple="true" :options="programsOptions" group-values="options" group-label="label" track-by="programID" label="dropdownLabel" :close-on-select="false" @select="e => handleOptionProgramAdd(e, groupedPrograms, 'program')" @remove="e => handleOptionProgramRemove(e, groupedPrograms, 'program')">
          <template slot="selection" slot-scope="{values, remove}"> <span v-for="option in values" :key="option.programID">
              <span v-if="option.dropdownLabel !== 'Select All'" class="multiselect__tag">
                {{ option.dropdownLabel }}<i class="multiselect__tag-icon" @click="remove(option)"></i></span>
            </span></template>
        </multiselect>
      </b-form-group>
    </b-col>

  </b-row>
  <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
    <b-row>
      <b-colxx xxs="12">
        <!-- :api-url="false"  -->
        <!-- :query-params="makeQueryParams" :per-page="perPage" :reactive-api-url="false"
         pagination-path :row-class="onRowClass" @vuetable:pagination-data="onPaginationData" @vuetable:row-clicked="rowClicked" @vuetable:cell-rightclicked="rightClicked" @vuetable:load-error="handleLoadError" @vuetable:cell-clicked="cellclicked" @vuetable:loading="onVuetableLoading" @vuetable:loaded="onVuetableLoaded" -->

        <b-card>
          <vuetable ref="vuetable" track-by="applicationID" :fields="fields" :css="css.table">
          </vuetable>
          <!-- <vuetable-pagination-bootstrap class="mt-4" ref="pagination" @vuetable-pagination:change-page="onChangePage" /> -->
        </b-card>
      </b-colxx>
    </b-row>
  </b-overlay>
</div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
// import VuetablePaginationBootstrap from '../../../../components/Common/VuetablePaginationBootstrap'
import {
  BeaconapiUrl
} from '../../../../constants/config'
import DatatableHeading from '../../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../../constants/VuetableBootstrapconfig'
import {
  mapMutations
} from 'vuex'
import moment from 'moment/moment.js'
import ApplicationMixins from '../../../../mixins/ApplicationMixins.vue'
import ProgramMixin from '../../../../mixins/ProgramMixin.vue'
import ReportsMixin from '../../../../mixins/ReportsMixin.vue'
import Multiselect from 'vue-multiselect'
import JsonExcel from 'vue-json-excel'

export default {
  props: {
    applicationID: {
      type: Number,
      default: 1
    }
  },
  mixins: [ApplicationMixins, ProgramMixin, ReportsMixin],
  components: {
    Vuetable,
    // 'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    multiselect: Multiselect,
    downloadExcel: JsonExcel
  },
  data () {
    var _idfield = 'applicationID'
    return {
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/application/application-table`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      fields: [{
        name: _idfield,
        sortField: _idfield,
        title: 'Company ID #',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'number'
      },
      {
        name: 'Company Legal Name',
        sortField: 'companyLegalName',
        title: 'Company Legal Name',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'date'
      },
      {
        name: 'Company DBA Name',
        sortField: 'companyDBAName',
        title: 'Company DBA Name',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'date'
      },
      {
        name: 'Program(s)',
        sortField: 'program',
        title: 'Program(s)',
        titleClass: '',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'Created date',
        sortField: 'createdDate',
        title: 'Created date',
        titleClass: 'text-muted',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'Due date',
        sortField: 'dueDate',
        title: 'Due date',
        titleClass: 'text-muted',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'Quote amount(s)',
        sortField: 'quoteAmount',
        title: 'Quote amount(s)',
        titleClass: 'text-muted',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      },
      {
        name: 'State',
        sortField: 'state',
        title: 'State',
        titleClass: 'text-muted',
        dataClass: 'text-muted',
        width: '12%',
        filterType: 'text'
      }
      ],
      json_data: [],
      customerCare: [],
      program: [],
      customerCareOptions: [],
      programsOptions: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search,
          filters: JSON.stringify(this.filters)
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    onRowClass (dataItem, index) {
      var outputclass = ''
      if (this.selectedItems.includes(dataItem[this.$data.idfield])) {
        outputclass = 'selected'
      }
      return outputclass
    },
    async onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      var applicationID = rightClickedItem.applicationID

      switch (action) {
        case 'pdf': {
          await this.getApplicationPdf(applicationID)
          break
        }
        default:
          break
      }
    },
    cellclicked (dataitem, field, event) {},
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]
      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({
        top: event.pageY,
        left: event.pageX
      })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    rowClicked () {

    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    formatMoney (value) {
      if (value === '' || value === 0) {
        return ''
      } else {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        })
      }
    },
    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD hh:mm A').format('MM/DD/YYYY hh:mm A')
      }
    },
    handleOptionAdd (event, list, name) {
      this[name].push(event)
      if (event.displayName === 'Select All') {
        const options = Array.from(new Set([...this[name], ...list].map(JSON.stringify))).map(JSON.parse)
        this[name] = options
        this.customerCareOptions[0].displayName = 'Deselect All'
      }
    },
    handleOptionRemove (event, name) {
      if (event.displayName === 'Deselect All' || event.displayName === 'Select All') {
        this.customerCare = []
        this.customerCareOptions[0].displayName = 'Select All'
      } else {
        this.customerCare = this.customerCare.filter(({
          displayName,
          employeeID
        }) => displayName !== event.displayName && displayName !== 'Select All')
        this.customerCareOptions[0].displayName = 'Select All'
      }
    },
    handleOptionProgramAdd (event, list, name) {
      this[name].push(event)
      if (event.dropdownLabel === 'Select All') {
        const allOptions = list.reduce((acc, data) => acc.concat(data.options), [])
        const options = Array.from(new Set([...this[name], ...allOptions].map(JSON.stringify))).map(JSON.parse)
        this.programsOptions[0].options[0].dropdownLabel = 'Deselect All'
        this[name] = options
      }
    },
    handleOptionProgramRemove (event, list, name) {
      if (event.dropdownLabel === 'Select All' || event.dropdownLabel === 'Deselect All') {
        this[name] = []
        this.programsOptions[0].options[0].dropdownLabel = 'Select All'
      } else {
        this[name] = this[name].filter(({
          dropdownLabel
        }) => dropdownLabel !== event.dropdownLabel && dropdownLabel !== 'Select All')
        this.programsOptions[0].options[0].dropdownLabel = 'Select All'
      }
    }
  },
  async created () {
    await this.getAllProgramsByType()
    await this.getAllCustomerCareSpecialist()
  },
  watch: {
    customerCareSpecialist (newData, oldData) {
      if (newData.length) {
        const options = [{
          active: false,
          azureGuid: null,
          createdByID: null,
          dateCreated: null,
          dateLastModified: null,
          displayName: 'Select All',
          emailAddress: null,
          employeeID: null,
          firstName: null,
          lastCompanyID: null,
          lastModifiedByID: null,
          lastName: null
        }, ...newData]
        this.customerCareOptions = options
      }
    },
    groupedPrograms (newData, oldData) {
      if (newData.length) {
        const options = [{
          label: '',
          options: [{
            active: true,
            activeBudget: false,
            activeInternational: false,
            displayOrder: 0,
            dropdownLabel: 'Select All',
            hfapLegacy: true,
            programDescription: 'Select All',
            programDetailDescription: null,
            programID: null,
            programInitials: 'Select All',
            programWebDescription: 'Select All',
            serviceInitials: null
          }]
        }, ...newData]
        this.programsOptions = options
      }
    }
  }
}
</script>
