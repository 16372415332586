<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      programs: [],
      program: {},
      statusCode: null,
      groupedPrograms: []
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage', 'appendDangerMessage']),
    async getAllPrograms (active = true) {
      await axios
        .get(`${BeaconapiUrl}/api/program`, { params: { active: active } })
        .then((result) => {
          this.programs = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAllProgramsByType (showAcuteCare = true) {
      await axios
        .get(`${BeaconapiUrl}/api/program/programs-grouped-by-type?showAcuteCare=${showAcuteCare}`)
        .then((result) => {
          this.groupedPrograms = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getProgramById (programId) {
      return await axios
        .get(`${BeaconapiUrl}/api/program/${programId}`)
        .then((result) => { this.program = result.data })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateProgram (program) {
      await axios
        .put(`${BeaconapiUrl}/api/program`, program)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Program ${result.data.programInitials} Successfully Updated!`)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async addNewProgram (program) {
      await axios
        .post(`${BeaconapiUrl}/api/program`, program)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(`Program ${result.data.programInitials} Successfully Added!`)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async deleteProgram (programId) {
      await axios
        .delete(`${BeaconapiUrl}/api/program/${programId}`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            this.setDangerMessage(error.message)
          } else if (error.request) {
            // client never received a response, or request never left
            this.setDangerMessage(error.message)
          } else {
            // anything else
            this.setDangerMessage(error.message)
          }
        })
    },
    async activatePrograms (programs) {
      await axios
        .put(`${BeaconapiUrl}/api/program/activate`, programs)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.appendDangerMessage(error)
        })
    }
  }
}
</script>
