<script>
import axios from 'axios'
import {
  BeaconapiUrl
} from '../constants/config'
import {
  mapMutations
} from 'vuex'

export default {
  data () {
    return {

      statusCode: null,
      processing: false,
      customerCareSpecialist: []
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage', 'appendDangerMessage']),
    async getAllCustomerCareSpecialist (active = true) {
      await axios
        .get(`${BeaconapiUrl}/api/reports/customercarespecialist`)
        .then((result) => {
          this.statusCode = result.status
          this.customerCareSpecialist = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }

  }
}
</script>
