<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config.js'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      statusCode: null,
      processing: false
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getApplicationPdf (applicationID) {
      this.processing = true
      await axios
        .get(
          `${BeaconapiUrl}/api/application/${applicationID}/pdf`,
          { responseType: 'blob' }
        )
        .then((response) => {
          // content disposition contains our file name along with type and content type.  need to retrieve  just the filename
          var contentDisposition = response.headers['content-disposition']
          var fileNameRaw = contentDisposition.split(';')[1].trim()
          var fileName = fileNameRaw.substring(9, fileNameRaw.length)
          // create blob object, append to html and then click it
          // https://stackoverflow.com/questions/53066089/how-to-open-pdf-blob-using-browsers-pdf-viewer-rather-than-downloading
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = fileName
          a.style.position = 'fixed'
          a.target = '_blank'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          this.processing = false
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
